import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function SliderTeam() {
    const { t } = useTranslation();
    return (
        <>
           <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"

            >
                <SwiperSlide>
                    <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                        <div className="single__team">
                            <div className="teamarea__img">
                                <img loading="lazy" src="assets/img/school/Shivam Sir.jpg" alt="team" />
                                <div className="teamarea__icon">
                                    <ul>
                                        <li>
                                            <a to="#"> <i className="icofont-facebook" /></a>
                                        </li>
                                        <li>
                                            <a to="#"> <i className="icofont-instagram" /></a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="teamarea__content">
                            
                                <p> {t('Mr. Shivam Mishra')}</p>
                                <p> {t('(Exam Coordinator)')}</p>
                                

                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                        <div className="single__team">
                            <div className="teamarea__img">
                                <img loading="lazy" src="assets/img/school/Neelesh kushwaha.jpeg" alt="team" />
                                <div className="teamarea__icon">
                                    <ul>
                                        <li>
                                            <a to="#"> <i className="icofont-facebook" /></a>
                                        </li>
                                        <li>
                                            <a to="#"> <i className="icofont-instagram" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="teamarea__content">

                            <p> {t('Mr. Neelesh Kushwaha')}</p>
                            <p> {t('(Digital Coordinator)')}</p>
                            

                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                        <div className="single__team">
                            <div className="teamarea__img">
                                <img loading="lazy" src="assets/img/school/Sandeep kushwaha.jpeg" alt="team" />
                                <div className="teamarea__icon">
                                    <ul>
                                        <li>
                                            <a to="#"><i className="icofont-facebook" /></a>
                                        </li>
                                        <li>
                                            <a to="#"> <i className="icofont-instagram" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="teamarea__content">
                            <p> {t('Mr. Sandeep Kushwaha')}</p>
                            <p> {t('(School Coordinator)')}</p>

                              

                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                        <div className="single__team">
                            <div className="teamarea__img">
                                <img loading="lazy" src="assets/img/school/Mr Subhash kushwaha.jpeg" alt="team" />
                                <div className="teamarea__icon">
                                    <ul>
                                        <li>
                                            <a to="#"><i className="icofont-facebook" /></a>
                                        </li>
                                        <li>
                                            <a to="#"> <i className="icofont-instagram" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="teamarea__content">

                            <p> {t('Mr. Subhash kushwaha')}</p>
                            <p> {t('(Head Teacher)')}</p>
                          

                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
