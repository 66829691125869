import React from 'react'
import { useTranslation } from 'react-i18next';


const HomeTestimonial = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='' style={{ overflow: "hidden" }}>
                <div className="aboutarea__3 testimonial__area__2 sp_bottom_90 sp_top_120">
                    <div className="container">
                        <div className="sec-title text-center" >
                            <h2> {t('Reviews')} !</h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div><br /><br />
                        <div className="row">

                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 custom__review__grid" data-aos="fade-up">
                                <div className="aboutarea__content__wraper__3">
                                    <div className="aboutarea__para__3">
                                        <p style={{ textAlign: "justify" }}> {t('Among Rewa top schools for children What a lovely and brand-new environment for children to grow in. The Rationalization Method is unique in that it emphasizes conceptual comprehension.')} {t('Educators put forth a lot of labor.It is very good school for budding talent,The best thing is it focus on all-round development of students.')}</p>
                                        <div className="aboutarea__icon__3">
                                            <i className="icofont-quote-left" />
                                        </div>
                                    </div>
                                    <div className="aboutarea__img__3">
                                        <img loading="lazy" src="assets/img/school/profile.png" alt="about" />
                                        <div className="aboutarea__img__name">
                                            <h6> {t('Jyoti Singh')}</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 custom__review__grid" data-aos="fade-up">
                                <div className="aboutarea__content__wraper__3">
                                    <div className="aboutarea__para__3">
                                        <p style={{ textAlign: "justify" }}> {t('This location is serene and near to the natural world. If you only look at this location, you will fall in love with the campus and surrounding flora.')} {t('Everything is amazing, including the gardens and infrastructure. In addition, there are additional amenities including volleyball, badminton, and a sizable cricket field.')}</p>
                                        <div className="aboutarea__icon__3">
                                            <i className="icofont-quote-left" />
                                        </div>
                                    </div>
                                    <div className="aboutarea__img__3">
                                        <img loading="lazy" src="assets/img/school/profile.png" alt="about" />
                                        <div className="aboutarea__img__name">
                                            <h6> {t('Muskan Tripathi')}</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 custom__review__grid" data-aos="fade-up">
                                <div className="aboutarea__content__wraper__3">
                                    <div className="aboutarea__para__3">
                                        <p style={{ textAlign: "justify" }}> {t('Students can focus intently and learn undisturbed in this environment. To provide a high-quality education, the school has top-notch facilities.')} {t('In addition, the school incorporates both indoor and outdoor games')} {t('into its curriculum to promote the kids overall growth.')}  </p>
                                        <br />
                                        <div className="aboutarea__icon__3">
                                            <i className="icofont-quote-left" />
                                        </div>
                                    </div>
                                    <div className="aboutarea__img__3">
                                        <img loading="lazy" src="assets/img/school/profile.png" alt="about" />
                                        <div className="aboutarea__img__name">
                                            <h6> {t('Amit Singh')}</h6>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="aboutarea__img__3">
                        <img loading="lazy" className="aboutarea__3__img__1" src="assets/img/about/about_6.png" alt="about" />
                        <img loading="lazy" className="aboutarea__3__img__2" src="assets/img/about/about_7.png" alt="about" />
                        <img loading="lazy" className="aboutarea__3__img__3" src="assets/img/about/about_9.png" alt="about" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeTestimonial