import React from 'react'
import { useTranslation } from 'react-i18next';

const Facility = () => {
  const { t } = useTranslation();
  return (
    <>

      <section className='' style={{ overflow: "hidden" }}>
        <section className="inner-banner">
          <h1 className="fw-bold text-center" style={{ color: "rgb(95, 45, 237)" }}>{t('Facility')}</h1>
        </section>
        <div>
          <div className="aboutarea aboutarea__single__course sp_top_50" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-md-12 col-12 ">
                  <div className="aboutarea__img__inner text-center">
                    <div className="aboutarea__img" data-tilt>
                      <img loading="lazy" style={{ borderRadius: 10 }} src="assets/img/school/Science Lab.jpg" alt />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-12 col-12 ">
                  <div className="aboutarea__content__wraper">
                    <div className="aboutarea__headding heading__underline">
                      <h2> {t('Science Lab')}</h2>
                    </div>
                    <div className="aboutarea__para">
                      <p style={{ textAlign: "justify" }}> {t('Additionally, it features a state-of-the-art Science lab for students in Classes V–X. students get access to the lab as well. The Science lab has been expanded, and the senior secondary students now have their own Science lab.')}<br /><br /> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutarea__2 about__single__course sp_top_100 sp_bottom_50">
            <div className="about__padding__wrap">
              <div className="container">
                <div className="row">

                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="aboutarea__content__wraper">
                      <div className="aboutarea__headding heading__underline">
                        <h2> {t('Transport')}</h2>
                      </div>
                      <div className="aboutarea__para aboutarea__para__2">
                        <p style={{ textAlign: "justify" }}> {t('Day scholars are the only ones with access to bus amenities. It is not permitted to remove this facility in mid-session. It is required that parents drop off and pick up their children from the same bus stop in the morning at least ten minutes early. The transportation department sets the bus stop for each route. Students that use the bus service are expected to do so for the duration of the class. Should someone use the bus service during the session, they must cover the full session charge. No leaving the bus facility throughout the course of the session. If so, the full session transportation fare will be billed.')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="about__right__wraper__2">
                      <div className="educationarea__img" data-tilt>
                        <img loading="lazy" style={{ width: '100%', borderRadius: 10 }} src="assets/img/school/Transport.jpg" alt="about" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutarea__2 aboutarea__4 about__single__course sp_bottom_50">
            <div className="about__padding__wrap">
              <div className="container">
                <div className="row">

                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="about__right__wraper__2 about__right__wraper__4">
                      <div className="educationarea__img" data-tilt>
                        <img loading="lazy" style={{ width: '100%', borderRadius: 10 }} src="assets/img/school/medicalcoll.jpg" alt="Certificate" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="aboutarea__content__wraper">
                      <div className="section__title__2">
                        <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
                          <h2> {t('Medical Facilities')}</h2>
                        </div>
                      </div>
                      <div className="aboutarea__para aboutarea__para__2">
                        <p style={{ textAlign: "justify" }}> {t('As the Medical Officer, a qualified and experienced physician has been assigned to the hostel on a regular basis. Students undergo medical examinations, and records are kept. If the doctor determines that a kid requires extra medical care, they notify the parents or local guardians. A fully stocked First Aid box is always available in the office, and minor injuries sustained by students during training, athletics, or games are promptly attended to. The school has one car on standby to handle any emergencies.')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="aboutarea aboutarea__single__course sp_top_50" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-7 col-md-12 col-12 ">
                  <div className="aboutarea__content__wraper">
                    <div className="aboutarea__headding heading__underline">
                      <h2> {t('Computer Lab')}</h2>
                    </div>
                    <div className="aboutarea__para">
                      <p style={{ textAlign: "justify" }}> {t('Additionally, it features a state-of-the-art computer lab for students in Classes V–X. students get access to the internet as well. The computer lab has been expanded, and the senior secondary students now have their own computer lab.')}<br /><br /> </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 col-md-12 col-12 ">
                  <div className="aboutarea__img__inner text-center">
                    <div className="aboutarea__img" data-tilt>
                      <img loading="lazy" style={{ borderRadius: 10 }} src="assets/img/school/Computer.jpg" alt />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="aboutarea aboutarea__single__course sp_top_50" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-md-12 col-12 ">
                  <div className="aboutarea__img__inner text-center">
                    <div className="aboutarea__img" data-tilt>
                      <img loading="lazy" style={{ borderRadius: 10 }} src="assets/img/school/Library.jpg" alt />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-12 col-12 ">
                  <div className="aboutarea__content__wraper">
                    <div className="aboutarea__headding heading__underline">
                      <h2> {t('Library')}</h2>
                    </div>
                    <div className="aboutarea__para">
                      <p style={{ textAlign: "justify" }}> {t('School libraries are similar to public libraries in that they contain books, films, recorded sound, periodicals, realia, and digital media. These items are not only for the education, enjoyment, and entertainment of all members of the school community, but also to enhance and expand the school curriculum')}<br /><br /> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>

    </>
  )
}

export default Facility