import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';
import { useTranslation } from 'react-i18next';


const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className='' style={{ overflow: "hidden" }}>
        <div className="footerarea">
          <div className="container">
            <div className="footerarea__wrapper footerarea__wrapper__2">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12" data-aos="fade-up">
                  <div className="footerarea__inner footerarea__about__us">
                    <div className="footerarea__heading">
                      <h3 className='mt-lg-1'> {t('About Us')}</h3>
                    </div>
                    {/* <div className="footerarea__heading">
                    <h3>About us</h3>
                  </div> */}

                    <div className="col-xl-3 col-lg-3">
                      <div className="copyright__logo">
                        {/* <Link to="https://html.themewin.com/"><img loading="lazy" src="assets/img/logo/logo_2.png" alt="logo" /></Link> */}
                      </div>
                    </div>
                    <div className="footerarea__content">
                      <p style={{ textAlign: "justify" }}> {t('Our School is designed to ensure an engaging, appealing and child-friendly setting for our students. Firstly, our infrastructure is in sync with the different learning modalities and school curriculum.')}</p>
                    </div>
                    <div className="foter__bottom__text">
                      <div className="footer__bottom__icon">
                        <i className="icofont-clock-time" />
                      </div>
                      <div className="footer__bottom__content">
                        <h6> {t('OPENING HOURES')}</h6>
                        <span> {t('Mon - Sat ( 9.30AM - 5.00PM )')}</span>
                        <span> {t('Sunday - Closed')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6" data-aos="fade-up">
                  <div className="footerarea__inner">
                    <div className="footerarea__heading">
                      <h3> {t('Usefull Links')}</h3>
                    </div>
                    <div className="footerarea__list">
                      <ul>
                        <li>
                          <Link to="/"> {t('Home')}</Link>
                        </li>
                        <li>
                          <Link to="/AboutUs"> {t('About Us')}</Link>
                        </li>
                        <li>
                          <Link to="/Facility"> {t('Facility')}</Link>
                        </li>
                        <li>
                          <Link to="/Activities"> {t('Activities')}</Link>
                        </li>
                        <li>
                          <Link to="/DirectorMessage"> {t('Director Message')}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                  <div className="footerarea__inner footerarea__padding__left">
                    <div className="footerarea__heading">
                      <h3> {t('Recent News')}</h3>
                    </div>
                    <div className="footerarea__list">
                      <ul>
                        <li>
                          <Link to="/Admission"> {t('Admission')}</Link>
                        </li>
                        <li>
                          <Link to="/Result"> {t('Top Result')}</Link>
                        </li>
                        <li>
                          <Link to="/Gallery">{t('Our Gallery')} </Link>
                        </li>
                        <li>
                          <Link to="/Blog"> {t('Our Blog')}</Link>
                        </li>
                        <li>
                          <Link to="/Contact"> {t('Contact')} </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12" data-aos="fade-up">
                  <div className="footerarea__right__wraper footerarea__inner">
                    <div className="footerarea__heading">
                      <h3> {t('Recent Post')}</h3>
                    </div>
                    <div className="footerarea__right__list ">
                      <ul className=''>
                        <li>
                          <Link to="#">
                            <div className="footerarea__right__img">
                              <img className='india' loading="lazy" src="csssudarshan/assets/images/200w.GIF" alt="footerphoto" />
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="footerarea__right__img">
                              <img loading="lazy" className='india' src="csssudarshan/assets/images/giphy.GIF" alt="footerphoto" />
                            </div>

                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <div className="footerarea__right__img">
                              <img loading="lazy" className='india' src="csssudarshan/assets/images/make.GIF" alt="footerphoto" />
                            </div>

                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerarea__copyright__wrapper footerarea__copyright__wrapper__2">
              <div className="row">
                {/* <div className="col-xl-3 col-lg-3">
                <div className="copyright__logo">
                  <Link to="https://html.themewin.com/"><img loading="lazy" src="assets/img/logo/logo_2.png" alt="logo" /></Link>
                </div>
              </div> */}
                <div className="col-xl-10 col-lg-10">
                  <div className="footerarea__copyright__content footerarea__copyright__content__2">
                    <p> {t('2024 © Admire Convent School Rewa | Designed by')} <a href='tel:+918982471422' style={{}}> {t('Web solution & digital marketing +91 8982471422')}</a></p>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2">
                  <div className="footerarea__icon footerarea__icon__2">
                    <ul>
                      <li><Link to="/"><i className="icofont-facebook" /></Link></li>
                      <li><Link to="/"><i className="icofont-instagram" /></Link></li>
                      {/* <li><Link to="http://vimeo.com/"><i className="icofont-vimeo" /></Link></li>
                    <li><Link to="http://linkedin.com/"><i className="icofont-linkedin" /></Link></li>
                    <li><Link to="http://skype.com/"><i className="icofont-skype" /></Link></li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WhatsappIcon />
      </section>

    </>
  )
}

export default Footer