import React from 'react'
import SliderTopResult from '../../component/SliderTopResult'
import { useTranslation } from 'react-i18next';

const Result = () => {
  const { t } = useTranslation();

    return (
        <>
            <section className='' style={{ overflow: "hidden" }}>
                <section className="inner-banner">
                    <h1 className="fw-bold text-center" style={{ color: "rgb(95, 45, 237)" }}> {t('Top Result')}</h1>
                </section>

                <section className='' style={{ overflow: "hidden" }}>
                <section className="result" >
                    <div className="container">
                        <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                            <h2> {t('Top Result')}</h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="result-box" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="table-responsive">
                                    <table className="table table-bordered mb-0" style={{textAlign:"center"}}>
                                        <thead>
                                            <tr>
                                                <th scope="col"> {t('Student Name')}</th>
                                                <th scope="col"> {t('Class')} </th>
                                                <th scope="col"> {t('Percentage')} </th>
                                                <th scope="col"> {t('Year')} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{textAlign:""}}> {t('Shubhi Dwivedi')}</td>
                                                <td>5th</td>
                                                <td>85%</td>
                                                <td>2022-23</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Prakash Kushwaha')}</td>
                                                <td>5th</td>
                                                <td>92%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Supriya Khatik')}</td>
                                                <td>8th</td>
                                                <td>89%</td>
                                                <td>2023-24</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Sagar Tiwari')}</td>
                                                <td>8th</td>
                                                <td>85%</td>
                                                <td>2022-23</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Divyanshi Dwivedi')} </td>
                                                <td>
                                                    10th
                                                </td>
                                                <td>87%</td>
                                                <td>2021-22</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Pranshi Kushwaha')} </td>
                                                <td>10th</td>
                                                <td>89%</td>
                                                <td>2022-23</td>
                                            </tr>
                                            <tr>
                                                <td> {t('Roshni Singh')} </td>
                                                <td>10th</td>
                                                <td>85%</td>
                                                <td>2023-24</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="result-list" style={{ borderRadius: "100%" }}>
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-md-3">
                                <div className="sec-title mb-3" data-aos="fade-up" data-aos-duration={1000}>
                                    <span className="title">Result</span>
                                    <h2>Top Result</h2>
                                    <div className="divider">
                                        <span className="fa fa-mortar-board" />
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <div className="autoplay">
                                    <SliderTopResult />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            </section>

        </>
    )
}

export default Result