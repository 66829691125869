import React, { useState } from 'react';
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter, BsYoutube } from 'react-icons/bs';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import i18next from "i18next"
import DropDown from '../DropDown';


const Hadear = () => {
  const [ismobile, setIsmobile] = useState(0);
  const { t } = useTranslation();
  const handleClick = (e) => {
    i18next.changeLanguage(e.target.value)
  }

  const [toggle, setToggle] = useState(false);
  return (
    <>

      <section className='' style={{ overflow: "hidden" }}>
        <header className="top-header py-2">
          <div className="container">
            <div className="row align-content-center" >
              <div className="col-xl-3 col-lg-4 col-md-12 text-start top-btn">
                {/* <DropDown onChange={(e) => handleClick(e)} /> */}
                <a href="https://acs.gainitservices.com/admin-home-screen" target="_blank">
                  <button type="button" value={'en'} className="btn theme-orange"> {t('Employe Login')}</button></a>
                {/* <Link to="/Contact">
                  <button type="button" value={'hi'} className="btn theme-orange"> {t('Quick Inquery')}</button></Link> */}

                <li>
                  <Link className=" text-decoration-none btn theme-orange">
                    <DropDown onChange={(e) => handleClick(e)} />
                  </Link>
                </li>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 py-2 fw-bold f-15 announcement">
                <i className="fa fa-bullhorn me-3" />
                <span> {t('Announcement')}</span>
              </div>
              <div className="col-xl-7 col-lg-5 col-md-8">
                <p className="marquee">
                  <span style={{ color: "white" }}> {t('2024-25 Admission Open In Admire Convent School, Rewa with the best facilities.')} </span>
                </p>
              </div>



            </div>
          </div>
        </header>
      </section>


      <nav className="navbar navbar-expand-lg sticky-top course nav a:hover ">
        <div className="container">
          <Link className="navbar-brand">
            <div className="logo-wrapper">
              <Link className='d-flex justify-content-center align-items-center' to="/">
                <img src="assets/img/logo/Admire Convent School.png" className="img-fluid" alt="Edusquad" />
              </Link>
            </div>
          </Link>
          <div className={ismobile ? "moblink" : "desklink"} onClick={() => setIsmobile(false)}>
            <ul >
              <Link className=" text-decoration-none" to="/">
                <li className='active'> {t('Home')}</li>
              </Link>
              <br />
              <Link className=" text-decoration-none" to="/AboutUs">
                <li className='li' > {t('About Us')}</li>
                <br />
              </Link>
              <Link className=" text-decoration-none" to="/DirectorMessage">
                <li className='li' > {t('Director Message')}</li>
              </Link>
              <br />

              <li>
                <Link className=" text-decoration-none" to="/Facility">
                  <li className='active'> {t('Facility')} </li>
                </Link>
              </li>
              <br />

              <li>
                <Link className=" text-decoration-none" to="/Activities">
                  <li> {t('Activities')}  </li>
                </Link>
              </li>
              <br />


              {/* <li>
                <Link className=" text-decoration-none" to="/Admission">
                  <li> Admission</li>
                </Link>
              </li> */}

              <li>
                <Link className=" text-decoration-none" to="/Result">
                  <li> {t('Top Result')} </li>
                </Link>
              </li>
              <br />

              <li>
                <Link className=" text-decoration-none" to="/Gallery">
                  <li> {t('Gallery')} </li>
                </Link>
              </li>
              <br />

              <li>
                <Link className=" text-decoration-none" to="/Blog">
                  <li> {t('Blog')} </li>
                </Link>
              </li>
              <br />

              <li>
                <Link className=" text-decoration-none" to="/Contact">
                  <li>  {t('Contact')} </li>
                </Link>
              </li>
              <br />

              {/* <li>
                <Link className=" text-decoration-none">
                  <DropDown onChange={(e) => handleClick(e)} />
                </Link>
              </li> */}

            </ul>
          </div>

          <button className='vavbutn' onClick={() => setIsmobile(!ismobile)} style={{ marginBottom: "60px" }}>
            {ismobile ? < IoMdClose /> : < GiHamburgerMenu />}
          </button>
        </div>
      </nav>

    </>
  )
}
export default Hadear