import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderTopResult() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"

            >
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000} >
                        <img src="assets/img/school/TopResult_1.png" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text" >
                            <a href="assets/img/school/TopResult_1.png" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>

                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/img/school/TopResult_2.jpg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/img/school/TopResult_2.jpg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/img/school/TopResult_3.jpeg" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/img/school/TopResult_3.jpeg" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>

                        </div>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <div className="result_img m-2" data-aos="zoom-in" data-aos-duration={1000}>
                        <img src="assets/img/school/TopResult_1.png" className="img-fluid" alt="Result" />
                        <div className="overlay" />
                        <div className="overlay-text">
                            <a href="assets/img/school/TopResult_1.png" data-fancybox="gallery">
                                <i className="fa fa-plus fa-2x" />
                            </a>

                        </div>
                    </div>
                </SwiperSlide> */}

               

            </Swiper>
        </>
    );
}
