import React from 'react'
import { useTranslation } from 'react-i18next';

const Activities = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className='' style={{ overflow: "hidden" }}>
        <section className="inner-banner">
          <h1 className="fw-bold text-center" style={{ color: "rgb(95, 45, 237)" }}> {t('Activities')}</h1>
        </section>


        <div>
          <div className="aboutarea aboutarea__single__course sp_top_50" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-md-12 col-12 ">
                  <div className="aboutarea__img__inner text-center">
                    <div className="aboutarea__img" data-tilt>
                      <img loading="lazy" style={{ borderRadius: 10 }} src="assets/img/school/Sports and Athletics.jpg" alt />
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-7 col-md-12 col-12 ">
                  <div className="aboutarea__content__wraper">
                    <div className="aboutarea__headding heading__underline">
                      <h2> {t('Sports and Athletics')}</h2>
                    </div>
                    <div className="aboutarea__para">
                      <p style={{ textAlign: "justify" }}> {t('Our all-inclusive sports program promotes the growth of students physical prowess, good sportsmanship, and collaborative skills. With our state-of-the-art facilities and qualified coaches, students can play football, basketball, cricket, swimming, and athletics, among other sports.')}<br /><br /> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutarea__2 about__single__course sp_top_100 sp_bottom_50">
            <div className="about__padding__wrap">
              <div className="container">
                <div className="row">

                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="aboutarea__content__wraper">
                      <div className="aboutarea__headding heading__underline">
                        <h2> {t('Arts and Culture')}</h2>
                      </div>
                      <div className="aboutarea__para aboutarea__para__2">
                        <p style={{ textAlign: "justify" }}> {t('The arts are essential for fostering self-expression and creativity. Students can explore their artistic abilities through a variety of activities at our school, including dance, music, theater, painting, and sculpture. We regularly host exhibitions and performances to highlight their creative accomplishments.')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="about__right__wraper__2">
                      <div className="educationarea__img" data-tilt>
                        <img loading="lazy" style={{ width: '100%', borderRadius: 10 }} src="assets/img/school/Arts and.jpg" alt="about" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutarea__2 aboutarea__4 about__single__course sp_bottom_50">
            <div className="about__padding__wrap">
              <div className="container">
                <div className="row">

                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="about__right__wraper__2 about__right__wraper__4">
                      <div className="educationarea__img" data-tilt>
                        <img loading="lazy" style={{ width: '100%', borderRadius: 10 }} src="assets/img/school/club-society-2.png" alt="Certificate" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="aboutarea__content__wraper">
                      <div className="section__title__2">
                        <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
                          <h2>{t('Clubs and Societies')}</h2>
                        </div>
                      </div>
                      <div className="aboutarea__para aboutarea__para__2">
                        <p style={{ textAlign: "justify" }}> {t('We have a large selection of clubs and societies to suit a variety of passions and interests. Students can get involved in clubs including environmental conservation, debating, robotics, coding, photography, and community service. These clubs give students a place to explore their interests, pick up new skills, and work on worthwhile initiatives.')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="instructor sp_bottom_100">
            <div className="about__padding__wrap">
              <div className="container">
                <div className="row">

                 


                  <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="instructor__inner__wraper">
                      <div className="instructor__list">
                        <ul>
                          <li data-aos="fade-up">
                            <div className="instructor__heading">
                              <h3> {t('Field Trips and Excursions')}</h3>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="instructor__content__wraper" data-aos="fade-up">
                        <p style={{ textAlign: "justify" }}> {t('We think that a comprehensive education requires experiential learning. Field trips and excursions to a variety of educational and recreational locations are planned by our school. These field trips offer students practical experiences, augment their understanding, and cultivate an enthusiasm for discovery and adventure.')}</p>
                      </div>
                    </div>
                  </div>


                  <div className="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="instructor__sidebar" data-tilt>
                      <div className="instructor__sidebar__img" data-aos="fade-up">
                        <img loading="lazy" style={{ width: '100%', borderRadius: 10 }} src="assets/img/school/40.jpeg" alt="team" />
                        <div className="instructor__sidebar__small__img">
                          <img loading="lazy" style={{ width: '100%', borderRadius: 10 }} src="assets/img/school/40.jpeg" alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutarea__2 aboutarea__4 about__single__course sp_bottom_50">
            <div className="about__padding__wrap">
              <div className="container">
                <div className="row">

                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="about__right__wraper__2 about__right__wraper__4">
                      <div className="educationarea__img" data-tilt>
                        <img loading="lazy" style={{ width: '100%', borderRadius: 10 }} src="assets/img/school/2 - Copy.jpeg" alt="Certificate" />
                      </div>
                    </div>
                  </div>


                  <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                    <div className="aboutarea__content__wraper">
                      <div className="section__title__2">
                        <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
                          <h2> {t('Competitions and Events')}</h2>
                        </div>
                      </div>
                      <div className="aboutarea__para aboutarea__para__2">
                        <p style={{ textAlign: "justify" }}> {t('We encourage our pupils to take part in a range of intra- and inter-school contests and activities. Academic tests, science fairs, sporting events, art exhibits, and festivals are a few examples of these. These kinds of activities help our students develop a sense of achievement, teamwork, and healthy competition.')}</p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Activities