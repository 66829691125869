import React from 'react'
import MissionVision from '../home/MissionVision'
import HomeAbout from '../home/HomeAbout'
import { useTranslation } from 'react-i18next';

const DirectorMessage = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className='' style={{ overflow: "hidden" }}>
        <section className="inner-banner">
          <h1 className="fw-bold text-center" style={{ color: "rgb(95, 45, 237)" }}> {t('Director Message')}</h1>

        </section>

        <div className="instructor sp_bottom_100">
          <div className="about__padding__wrap">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="instructor__sidebar" data-tilt>
                    <div className="instructor__sidebar__img" data-aos="fade-up">
                      <img loading="lazy" src="assets/img/school/tilak.png" alt="team" />
                      <div className="instructor__sidebar__small__img">
                        <img loading="lazy" src="assets/img/about/about_4.png" alt="img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                  <div className="instructor__inner__wraper">
                    <div className="instructor__list">
                      <ul>
                        <li data-aos="fade-up">
                          <div className="instructor__heading">
                            <h3> {t('Mr. Tillak Kushwah')}</h3>
                            <p> {t('Director Message')}</p>
                          </div>
                        </li>
                        {/* <li data-aos="fade-up">
                        <div className="instructor__review">
                          <span>Review:</span>
                          <div className="instructor__star">
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <i className="icofont-star" />
                            <span>(44)</span>
                          </div>
                        </div>
                      </li> */}
                        <li data-aos="fade-up">
                          <div className="instructor__follow">
                            <span> {t('Follow Us')}</span>
                            <div className="instructor__icon">
                              <ul>
                                <li>
                                  <a to="#"><i className="icofont-facebook" /></a>
                                </li>
                                <li>
                                  <a to="#"><i className="icofont-twitter" /></a>
                                </li>
                                <li>
                                  <a to="#"><i className="icofont-instagram" /></a>
                                </li>
                                <li>
                                  <a to="#"><i className="icofont-youtube-play" /></a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="instructor__content__wraper" data-aos="fade-up">
                      <h6> {t('Director Message')}</h6>
                      <p style={{ textAlign: "justify" }}> {t('I would like to send a warm welcome to all prospective students and their parents on behalf of Admire Convent School. Our school is committed to developing young minds and offering a ladder to success by emphasizing student-centered learning. Our renowned faculty, who combine expertise and experience, guarantees high-quality instruction in line with changing business trends. Our goal is to shape resilient people who are well-prepared to face obstacles in life and have solid academic backgrounds. We provide a wide range of courses and cutting-edge technologies because we think knowledge is essential. Our goal is to provide society with qualified professionals.')}</p>
                      <p style={{ textAlign: "justify" }}> {t('As we shape tomorrow leaders, I invite students and parents to join us on this transformative journey. Together, well forge a brighter future, and our pursuit of excellence will propel Admire Convent School to global recognition. Remember, with Admire Convent School, the possibilities are limitless.')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MissionVision />
        <HomeAbout />
      </section>
    </>
  )
}

export default DirectorMessage