import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';


import 'swiper/css';



import { Autoplay } from 'swiper/modules';

export default function SliderHomeBanner() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}

                
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="">
                        <img src="assets/img/school/banner/banner_2.png" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInLeft delay-0.5s">
                            
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="assets/img/school/banner/banner_1.png" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated ">

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="assets/img/school/banner/02.png" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated ">

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="">
                        <img src="assets/img/school/banner/03.png" alt="education" className="img-fluid" />
                        <div className="carousel-caption animated fadeInDown delay-0.5s">
                            {/* <h1 className="fw-bold" style={{ color: "red" }}>Education is not the filling of a pail, but the lighting of a fire.</h1>
                            <h6 className="banner-desc" style={{ color: "white" }}>  Education our vision is to make you proud, to give your students the best education.</h6>
                            <Link to="/Contact" className="theme-orange btn mt-3 apply-now">Apply Now</Link> */}
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
}
