import React from 'react'
import { useTranslation } from 'react-i18next';
import SliderTeam from '../../component/slidercomponent/SliderTeam';
export const metadata = {
    title:
        "best admire convent school rewa, top best admire convent school rewa, top admire convent school rewa, best school, rewa school.",
    description:
        "One of the top providers of health and safety courses in India is Admire Convent School. is endorsed by the Indian government.During the course of its more than ten years of operation Admire Convent School, which opened its doors in 2014, has accomplished a great deal and maintained an integrated development of all services to meet the needs of the students. Thousands of students careers in the health and safety sector have been significantly influenced by us Additionally, we continued our tradition of quality control.",
    keywords:
        "best admire convent school rewa, top best admire convent school rewa, top admire convent school rewa, best school, rewa school.",
};

const Team = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='' style={{ overflow: "hidden" }}>
                <div className="teamarea sp_bottom_100 sp_top_50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12" data-aos="fade-up">
                                <div className="section__title__2 text-center teamarea__margin">
                                    <div className="section__small__title">
                                        <span> {t('OUR TEAM')}</span>
                                    </div>
                                    <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
                                        <h2 className=''> {t('Expert Teacher')}</h2>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <SliderTeam />
                            {/* <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="single__team">
                                    <div className="teamarea__img">
                                        <img loading="lazy" src="assets/img/school/Shivam Sir.jpg" alt="team" />
                                        <div className="teamarea__icon">
                                            <ul>
                                                <li>
                                                    <a to="#"> <i className="icofont-facebook" /></a>
                                                </li>
                                                <li>
                                                    <a to="#"> <i className="icofont-instagram" /></a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="teamarea__content">
                                        <p>Mr. Shivam Sir</p>
                                        <p>(Teacher)</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="single__team">
                                    <div className="teamarea__img">
                                        <img loading="lazy" src="assets/img/school/Neelesh kushwaha.jpeg" alt="team" />
                                        <div className="teamarea__icon">
                                            <ul>
                                                <li>
                                                    <a to="#"> <i className="icofont-facebook" /></a>
                                                </li>
                                                <li>
                                                    <a to="#"> <i className="icofont-instagram" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="teamarea__content">
                                        <p>Mr. Neelesh kushwaha</p>
                                        <p>(Teacher)</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="single__team">
                                    <div className="teamarea__img">
                                        <img loading="lazy" src="assets/img/school/Sandeep kushwaha.jpeg" alt="team" />
                                        <div className="teamarea__icon">
                                            <ul>
                                                <li>
                                                    <a to="#"><i className="icofont-facebook" /></a>
                                                </li>
                                                <li>
                                                    <a to="#"> <i className="icofont-instagram" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="teamarea__content">
                                        <p>Mr. Sandeep kushwaha</p>
                                        <p>(Teacher)</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="single__team">
                                    <div className="teamarea__img">
                                        <img loading="lazy" src="assets/img/school/Mr Subhash kushwaha.jpeg" alt="team" />
                                        <div className="teamarea__icon">
                                            <ul>
                                                <li>
                                                    <a to="#"><i className="icofont-facebook" /></a>
                                                </li>
                                                <li>
                                                    <a to="#"> <i className="icofont-instagram" /></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="teamarea__content">
                                        <p>Mr. Subhash kushwaha</p>
                                        <p>(Teacher)</p>

                                    </div>
                                </div>
                            </div> */}
                           
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Team