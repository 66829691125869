import React from 'react'
import HomeBanner from './home/HomeBanner'
import MissionVision from './home/MissionVision'
import HomeAbout from './home/HomeAbout'
import HomeBlog from './home/HomeBlog'
import HomeTestimonial from './home/HomeTestimonial'
import HomeGallery from './home/HomeGallery'
import Team from './home/Team'
import HomeTopResult from './home/HomeTopResult'
import DropDown from '../component/DropDown'

const Home = () => {
  return (
    <>
      <HomeBanner />
      <MissionVision />
      <HomeAbout />
      <HomeBlog />
      <HomeTestimonial />
      <HomeGallery />
      <Team />
      <HomeTopResult />


    </>
  )
}

export default Home