import React from "react";

const WhatsappIcon = () => {
  return (
    <>
      <div className="whatsapp_float">
        <a href="https://wa.me/919165355872" target="_blank">
          <img
            src="assets/img/school/WhatsappLogo.png"
            width="50px"
            className="whatsapp_float_btn"
          />
        </a>
      </div>
    </>
  );
};

export default WhatsappIcon;
