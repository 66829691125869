import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from "i18next"


const HomeAbout = () => {
    const { t } = useTranslation();
    return (
        <>

            <section className='' style={{ overflow: "hidden" }}>
                <div className="aboutarea__2 sp_top_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                                <div className="about__right__wraper__2">
                                    <div className="educationarea__img" data-tilt>
                                        <img loading="lazy" className="aboutarea__2__img__1" src="assets/img/about/about_2.png" alt="education" />
                                        <img loading="lazy" className="aboutarea__2__img__2" src="assets/img/about/about_19.png" alt="education" />
                                        <img loading="lazy" className="aboutarea__2__img__3" src="assets/img/about/about_4.png" alt="education" />
                                        <img loading="lazy" className="aboutarea__2__img__4" src="assets/img/about/about_11.png" alt="education" />
                                    </div>
                                    <div className="aboutarea__2__text">
                                        <div className="aboutarea__counter">
                                            <span className="counter">10</span> +
                                        </div>
                                        <p> {t('YEARS EXPERIENCE JUST ACHIVED')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                                <div className="aboutarea__content__wraper">
                                    <div className="aboutarea__button">
                                        <div className="default__small__button"> {t('About Us')}</div>
                                    </div>
                                    <div className="aboutarea__headding heading__underline">
                                        <h1><b style={{ fontWeight: "700" }}>{t('Welcome to the Admire Convent School')}</b></h1>
                                    </div>
                                    <div className="aboutarea__para aboutarea__para__2">
                                        <p style={{ textAlign: "" }}> {t('One of the top schools in India for health and safety courses is Admire Convent School. is endorsed by the Indian government')}</p>



                                        <p style={{textAlign:"justify"}}>{t('Since its founding in 2014, Admire Convent School Hours and Semi-Sectary School has accomplished a great deal and maintained an integrated development of all services to meet the demands of the pupils. Thousands of students careers in the health and safety sector have been significantly influenced by us.Additionally,we continued our tradition of quality control.')}</p>
                                    </div>
                                    <div className="aboutarea__list__2">
                                        <ul>
                                            <li>
                                                <i className="icofont-check" />{t('fully CCTV Campus')} 
                                            </li>
                                            <li>
                                                <i className="icofont-check" />{t('30 Student in each Classroom')}
                                            </li>
                                            <li>
                                                <i className="icofont-check" />{t('Hygienic lunch facility')} 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="counterarea sp_bottom_100 sp_top_50">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="assets/img/counter/counter__1.png" alt="counter" />
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">20</span>+
                                        </div>
                                        <p>{t('Award')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="assets/img/counter/counter__2.png" alt="counter" />
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">390</span>+
                                        </div>
                                        <p>{t('Students')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="assets/img/counter/counter__3.png" alt="counter" />
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">18</span>+
                                        </div>
                                        <p>{t('Teachers')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="assets/img/counter/counter__4.png" alt="counter" />
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">16</span>+
                                        </div>
                                        <p>{t('Class Room')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAbout