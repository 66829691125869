import React from 'react'
import HomeBlog from '../home/HomeBlog'
import { useTranslation } from 'react-i18next';

const Blog = () => {

  const { t } = useTranslation();

  return (
    <>
           <section className='' style={{ overflow: "hidden" }}>
      <section className="inner-banner">
        <h1 className="fw-bold text-center" style={{ color: "rgb(95, 45, 237)" }}> {t('Blog')}</h1>
      </section>
      <br />
      <br />
      <HomeBlog />
      </section>
    </>
  )
}

export default Blog