import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from "i18next"

const HomeBlog = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='' style={{ overflow: "hidden" }}>
                <div className="gridarea gridarea__2">
                    <div className="container">
                        <div className="row grid__row">
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12" data-aos="fade-up">
                                <div className="section__title__2">
                                    <div className="section__title__button">
                                        <div className="default__small__button"> {t('Our Blog')}</div>
                                    </div>
                                    <div className="section__title__heading__2 section__title__heading__3 heading__fontsize__2">
                                        <h2> {t('Blog')}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row grid" data-aos="fade-up">
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter1 filter3">
                                <div className="gridarea__wraper">
                                    <div className="gridarea__img">
                                        <a to=""><img loading="lazy" src="assets/img/school/1.JPEG" alt="grid" /></a>
                                        <div className="gridarea__small__button">
                                            <div className="grid__badge">01th January,2024</div>
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <a to="#"><i className="icofont-heart-alt" /></a>
                                        </div>
                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li>
                                                    <i className="icofont-clock-time" /> 01th January,2024
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3><a to="">{t('How can students effectively improve their communication skills?')}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter2 filter3">
                                <div className="gridarea__wraper">
                                    <div className="gridarea__img">
                                        <img loading="lazy" src="assets/img/school/35.JPEG" alt="grid" />
                                        <div className="gridarea__small__button">
                                            <div className="grid__badge blue__color">26 January,2024</div>
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <a to="#"><i className="icofont-heart-alt" /></a>
                                        </div>
                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li>
                                                    <i className="icofont-clock-time" /> 26 January,2024
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3><a to="#"> {t('Physical education is vital for young children.')}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 grid-item filter4 filter5">
                                <div className="gridarea__wraper">
                                    <div className="gridarea__img">
                                        <a to=""><img loading="lazy" src="assets/img/school/17.JPEG" alt="grid" /></a>
                                        <div className="gridarea__small__button">
                                            <div className="grid__badge pink__color">6 Sept, 2023</div>
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <a to="#"><i className="icofont-heart-alt" /></a>
                                        </div>
                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>

                                                <li>
                                                    <i className="icofont-clock-time" /> 6 Sept, 2023
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3><a to=""> {t('Admire Convent School')} {t('Bright Janmashtami Celebration.')}</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeBlog