import React from 'react'
import { Link } from 'react-router-dom';
import SliderHomeBanner from '../../component/slidercomponent/SliderHomeBanner';


const HomeBanner = () => {
    return (
        <>
            {/* 
            <div className="herobannerarea herobannerarea__2 herobannerarea__kindergarten">
                <div className="swiper ecommerce__slider">
                    <div className="herobannerarea__slider__wrap swiper-wrapper">
                        <div className="swiper-slide herobannerarea__single__slider">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="herobannerarea__content__wraper">
                                            <div className="herobannerarea__title">
                                                <div className="herobannerarea__small__title">
                                                    <span>Education Solution</span>
                                                </div>
                                                <div className="herobannerarea__title__heading__2 herobannerarea__title__heading__3">
                                                    <h2>Best Kindergarten Awesome <span>Solution</span></h2>
                                                </div>
                                            </div>
                                            <div className="herobannerarea__text herobannerarea__text__2">
                                                <p>Lorem Ipsum is simply dummy text of the printing typesetting
                                                    <br />industry. Lorem Ipsum has been </p>
                                            </div>
                                            <div className="hreobannerarea__button__2">
                                                <a className="default__button" href="#">Buy Now</a>
                                                <a className="default__button hreobannerarea__button__3" href="#">Explore
                                                    More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="herobanner__video registerarea__video">
                                            <img loading="lazy" src="assets/img/herobanner/herobanner__slider__5.png" alt="Video Banner" />
                                            <div className="video__pop__btn">
                                                <a className="video-btn" href="https://www.youtube.com/watch?v=vHdclsdkp28">
                                                    <img loading="lazy" src="assets/img/icon/video.png" alt /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide herobannerarea__single__slider">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="herobannerarea__content__wraper text-center">
                                            <div className="herobannerarea__title">
                                                <div className="herobannerarea__small__title">
                                                    <span>Coror Solution</span>
                                                </div>
                                                <div className="herobannerarea__title__heading__2 herobannerarea__title__heading__3">
                                                    <h2>Best Colorful <span>Career</span></h2>
                                                </div>
                                            </div>
                                            <div className="herobannerarea__text herobannerarea__text__2">
                                                <p>Lorem Ipsum is simply dummy text of the printing typesetting
                                                    <br />industry. Lorem Ipsum has been </p>
                                            </div>
                                            <div className="herobannerarea__price">
                                                $32.00 <del> $67.00</del>
                                                <span>30% Off</span>
                                            </div>
                                            <div className="hreobannerarea__button__2">
                                                <a className="default__button" href="#">Buy Now</a>
                                                <a className="default__button hreobannerarea__button__3" href="#">Explore
                                                    More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="herobannerarea__img">
                                            <img loading="lazy" src="assets/img/herobanner/herobanner__slider__6.png" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide herobannerarea__single__slider">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="herobannerarea__content__wraper text-end">
                                            <div className="herobannerarea__title">
                                                <div className="herobannerarea__small__title">
                                                    <span>Minimal Solution</span>
                                                </div>
                                                <div className="herobannerarea__title__heading__2 herobannerarea__title__heading__3">
                                                    <h2>Reading Table <span>Lamp</span></h2>
                                                </div>
                                            </div>
                                            <div className="herobannerarea__text herobannerarea__text__2">
                                                <p>Lorem Ipsum is simply dummy text of the printing typesetting
                                                    <br />industry. Lorem Ipsum has been </p>
                                            </div>
                                            <div className="herobannerarea__price">
                                                $32.00 <del> $67.00</del>
                                                <span>30% Off</span>
                                            </div>
                                            <div className="hreobannerarea__button__2">
                                                <a className="default__button" href="#">Buy Now</a>
                                                <a className="default__button hreobannerarea__button__3" href="#">Explore
                                                    More</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 col-lg-5 col-md-8 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="herobannerarea__img">
                                            <img loading="lazy" src="assets/img/herobanner/herobanner__slider__7.png" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider__controls__wrap slider__controls__pagination slider__controls__arrows">
                    <div className="swiper-button-next arrow-btn" />
                    <div className="swiper-button-prev arrow-btn" />
                    <div className="swiper-pagination" />
                </div>
                <div className="herobannerarea__icon__2">
                    <img loading="lazy" className="herobanner__common__img herobanner__img__1" src="assets/img/herobanner/herobanner__1.png" alt="photo" />
                    <img loading="lazy" className="herobanner__common__img herobanner__img__2" src="assets/img/herobanner/herobanner__2.png" alt="photo" />
                    <img loading="lazy" className="herobanner__common__img herobanner__img__3" src="assets/img/herobanner/herobanner__3.png" alt="photo" />
                    <img loading="lazy" className="herobanner__common__img herobanner__img__4" src="assets/img/herobanner/herobanner__4.png" alt="photo" />
                    <img loading="lazy" className="herobanner__common__img herobanner__img__5" src="assets/img/herobanner/herobanner__5.png" alt="photo" />
                </div>
            </div> */}
            <section className='' style={{ overflow: "hidden" }}>
                <div className="page-wrapper" id="page-wrapper">
                    <div id="demo" className="carousel slide" data-bs-ride="carousel">
                        <ul className="carousel-indicators">
                            <li data-bs-target="#demo" data-bs-slide-to={0} className="active" />
                            <li data-bs-target="#demo" data-bs-slide-to={1} />
                            <li data-bs-target="#demo" data-bs-slide-to={2} />
                            <li data-bs-target="#demo" data-bs-slide-to={3} />
                        </ul>
                        <div className="carousel-inner">

                            <SliderHomeBanner />


                            {/* <div className="carousel-item active">
                                <img src="assets/img/school/banner/01.png" alt="education" className="img-fluid" />
                                <div className="carousel-caption animated fadeInLeft delay-0.5s">
                                    <h1 className="fw-bold" style={{ color: "#F2277E" }}>An investment in knowledge pays the best interest.</h1>
                                    <h6 className="banner-desc" style={{ color: "white" }}>We had such a great time in Education</h6>
                                    <Link to="/Contact" className="theme-orange btn mt-3 apply-now">Apply Now</Link>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="assets/img/school/banner/02.png" alt="education" className="img-fluid" />
                                <div className="carousel-caption animated fadeInUp delay-0.5s">

                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="assets/img/school/banner/03.png" alt="education" className="img-fluid" />
                                <div className="carousel-caption animated fadeInDown delay-0.5s">
                                    <h1 className="fw-bold" style={{ color: "red" }}>Education is not the filling of a pail, but the lighting of a fire.</h1>
                                    <h6 className="banner-desc" style={{ color: "white" }}>  Education our vision is to make you proud, to give your students the best education.</h6>
                                    <Link to="/Contact" className="theme-orange btn mt-3 apply-now">Apply Now</Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HomeBanner