import React from 'react'
import { useTranslation } from 'react-i18next';

const Gallery = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className='' style={{ overflow: "hidden" }}>
        <section className="inner-banner">
          <h1 className="fw-bold text-center" style={{ color: "rgb(95, 45, 237)" }}> {t('Gallery')}</h1>

        </section>
        <section className="course">
          <div className="container">
            <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
              <span className="title"> {t('Our Gallery')}</span>
              <h2> {t('Life At Campus')}</h2>
              <div className="divider">
                <span className="fa fa-mortar-board" />
              </div>
            </div>
            <ul className="nav nav-pills mb-3 p-0 justify-content-center" id="pills-tab" role="tablist" data-aos="fade-up" data-aos-duration={1000}>
             

              <li className="nav-item">
                <a className="nav-link" id="Events-tab" data-bs-toggle="pill" href="#Events" role="tab" aria-selected="false"> {t('Event')}</a>
              </li>

              <li className="nav-item">
                <a className="nav-link" id="Classroom-tab" data-bs-toggle="pill" href="#Classroom" role="tab" aria-selected="false"> {t('Field Trip')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="Class-room" data-bs-toggle="pill" href="#Classroom" role="tab" aria-selected="false">{t('Class Room')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="Sports-tab" data-bs-toggle="pill" href="#Sports" role="tab" aria-selected="false"> {t('Sport')}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" id="showall-tab" data-bs-toggle="pill" href="#showall" role="tab" aria-controls="showall" aria-selected="true"> {t('Show All')}</a>
              </li>
             
            </ul>
            <div className="row">
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane  fade show active" id="showall" role="tabpanel" aria-labelledby="showall-tab">
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/1.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/1.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/2.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/2.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/7.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/7.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/8.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/8.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/10.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/10.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/15.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/15.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/17.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/17.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/19.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/19.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/28.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/28.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/29.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/29.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/25.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/25.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/32.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/32.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/35.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/35.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/37.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/37.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/38.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/38.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/39.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/39.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/42.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/42.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/43.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/43.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/46.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/46.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/50.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/50.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/49.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/49.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/55.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/55.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/56.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/56.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/57.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/57.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/58.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/58.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/59.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/59.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/60.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/60.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/62.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/62.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/65.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/65.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/67.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/67.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/68.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/68.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/69.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/69.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/70.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/70.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                </div>
                <div className="tab-pane  fade" id="Events" role="tabpanel" aria-labelledby="Events-tab">
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/2.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/2.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/8.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/8.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/10.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/10.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/16.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/16.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/17.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/17.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/21.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/21.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/29.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/29.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/38.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/38.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/59.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/59.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid " src="assets/img/school/60.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/60.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="img-fluid" src="assets/img/school/62.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/62.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/Arts and Culture.jpg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/Arts and Culture.jpg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>

                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="Sports" role="tabpanel" aria-labelledby="Sports-tab">
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/Sports and Athletics.jpg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/Sports and Athletics.jpg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>
                    </div>
                  </div>
                 
                </div>
                <div className="tab-pane fade" id="Classroom" role="tabpanel" aria-labelledby="Classroom-tab">
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/32.jpeg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/32.jpeg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>
                      
                    </div>
                  </div>
                </div>

                 <div className="tab-pane fade" id="Sports" role="tabpanel" aria-labelledby="Sports-tab">
                  <div className="Portfolio" data-aos="fade-up" data-aos-duration={1000}>
                    <img className="card-img" src="assets/img/school/Sports and Athletics.jpg" alt="Gallery" />
                    <div className="overlay" />
                    <div className="overlay-text">
                      <a href="assets/img/school/Sports and Athletics.jpg" data-fancybox="gallery">
                        <i className="fa fa-plus fa-2x" />
                      </a>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

    </>
  )
}

export default Gallery