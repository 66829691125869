import React, { useState } from 'react'

const DropDown = (props) => {
  const [toggle, setToggle] = useState(false);

  return (
    <>

      <div style={{ marginTop: "0px", fontWeight: "1000" }} >
        <select onChange={props.onChange} style={{}} >
          <option style={{ background: "red)" }} >
            <b style={{ background: "red)" }} onClick={() => setToggle(!toggle)}>Laguage</b></option>
          <option value={'en'} >English</option>
          <option value={'hi'} >Hindi</option>




        </select>
      </div>
    </>
  )
}

export default DropDown

