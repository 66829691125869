import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";


const HomeGallery = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className='' style={{ overflow: "hidden" }}>
                <section className="blog">
                    <div className="container">
                        <div className="sec-title text-center mb-3">
                            {/* <span className="title">BLOG &amp; NEWS</span> */}
                            <h2> {t('School Gallery')} </h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <Link to="/Gallery">
                                    <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                        <img src="assets/img/school/37.JPEG" className="img-fluid" alt="Blog" />
                                    </div></Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/Gallery">
                                    <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                        <img src="assets/img/school/7.JPEG" className="img-fluid" alt="Blog" />
                                    </div></Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/Gallery">
                                    <div className="post " data-aos="fade-up" data-aos-duration={550}>
                                        <img src="assets/img/school/46.JPEG" className="img-fluid" alt="Blog" />
                                    </div></Link>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-md-4">
                                <Link to="/Gallery">
                                    <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                        <img src="assets/img/school/47.jpeg" className="img-fluid" alt="Blog" />

                                    </div></Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/Gallery">
                                    <div className="post mb-xl-0 mb-lg-0 mb-md-0 mb-5" data-aos="fade-up" data-aos-duration={550}>
                                        <img src="assets/img/school/70.JPEG" className="img-fluid" alt="Blog" />
                                    </div></Link>
                            </div>
                            <div className="col-md-4">
                                <Link to="/Gallery">
                                    <div className="post " data-aos="fade-up" data-aos-duration={550}>
                                        <img src="assets/img/school/44.JPEG" className="img-fluid" alt="Blog" />
                                    </div></Link>
                            </div>
                        </div>
                    </div>
                </section>
            </section>

        </>
    )
}

export default HomeGallery